import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    IconButton,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
    Terminal as TerminalIcon,
    Monitor as MonitorIcon,
    Camera as CameraIcon,
} from 'lucide-react';

import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import {terminalTheme} from '../../styles/terminalThemeProvider';

function MarketingPage() {
    const cardData = [
        {
            icon: <MonitorIcon />,
            title: 'AI APPS AND EXPERIENCES',
            description: 'INDUSTRY.SOLUTIONS[tailored_to_needs]'
        },
        {
            icon: <TerminalIcon />,
            title: 'ENTERPRISE SOLUTIONS',
            description: 'SUPPORT.STATUS[24/7_assistance]'
        },
        {
            icon: <CameraIcon />,
            title: 'SCALABLE SOLUTIONS',
            description: 'ACCESS.LEVEL[founding_team]'
        }
    ];

    return (
        <ThemeProvider theme={terminalTheme}>
            <Box
                sx={{
                    bgcolor: 'background.default',
                    minHeight: '100vh',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'repeating-linear-gradient(0deg, rgba(0, 255, 0, 0.03) 0px, ' +
                            'rgba(0, 255, 0, 0.03) 1px, transparent 1px, transparent 2px)',
                        pointerEvents: 'none',
                    },
                }}
            >
                <Box
                    sx={{
                        py: 8,
                        bgcolor: 'background.default',
                        boxShadow: 'none',
                        position: 'relative',
                        animation: 'flicker 0.15s infinite',
                        '@keyframes flicker': {
                            '0%': {
                                opacity: 1,
                            },
                            '3%': {
                                opacity: 0.4,
                            },
                            '6%': {
                                opacity: 1,
                            },
                            '7%': {
                                opacity: 0.4,
                            },
                            '8%': {
                                opacity: 1,
                            },
                            '9%': {
                                opacity: 0.4,
                            },
                            '10%': {
                                opacity: 1,
                            },
                            '100%': {
                                opacity: 1,
                            }
                        },
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'repeating-linear-gradient(0deg, rgba(0, 255, 0, 0.03) 0px, ' +
                                'rgba(0, 255, 0, 0.03) 1px, transparent 1px, transparent 2px)',
                            pointerEvents: 'none',
                            animation: 'scanline 6s linear infinite',
                            '@keyframes scanline': {
                                '0%': {
                                    transform: 'translateY(-100%)',
                                },
                                '100%': {
                                    transform: 'translateY(100%)',
                                }
                            }
                        },
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'linear-gradient(rgba(0, 255, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 50%)',
                            backgroundSize: '100% 4px',
                            pointerEvents: 'none',
                            animation: 'flicker-lines 0.15s infinite',
                            '@keyframes flicker-lines': {
                                '0%': {
                                    opacity: 0.7,
                                },
                                '50%': {
                                    opacity: 1,
                                }
                            }
                        }
                    }}
                >
                    <Container maxWidth="lg">
                        <Box
                            sx={{
                                textAlign: 'center',
                                padding: '64px',
                                position: 'relative',
                                zIndex: 1
                            }}
                        >
                            <Typography
                                variant="overline"
                                sx={{
                                    opacity: 0.7,
                                    display: 'block',
                                    mb: 2,
                                    color: 'primary.main',
                                    textShadow: '0 0 8px rgba(0, 255, 0, 0.4)'
                                }}
                            >
                                SYSTEM.INIT //THE_JOINERY_MAIN_SEQUENCE
                            </Typography>
                            <Typography
                                variant="h2"
                                component="h1"
                                sx={{
                                    color: 'primary.main',
                                    textShadow: '0 0 8px rgba(0, 255, 0, 0.4)',
                                    animation: 'text-flicker 4s linear infinite',
                                    '@keyframes text-flicker': {
                                        '0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100%': {
                                            opacity: 1,
                                        },
                                        '20%, 21.999%, 63%, 63.999%, 65%, 69.999%': {
                                            opacity: 0.4,
                                        }
                                    }
                                }}
                            >
                                &gt;_THE JOINERY
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    mt: 2,
                                    color: 'primary.main',
                                    textShadow: '0 0 8px rgba(0, 255, 0, 0.4)',
                                    fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem' },
                                    '& br': {
                                        display: { xs: 'inline', sm: 'none' } // Only show breaks on mobile
                                    }
                                }}
                            >
                                [BUILDING.{' '}
                                <br />
                                BEAUTIFUL.{' '}
                                <br />
                                THINGS]
                            </Typography>
                        </Box>
                    </Container>
                </Box>

                {/* Features Section */}
                <Container maxWidth="lg" sx={{ py: 8 }}>
                    <Grid container spacing={4}>
                        {cardData.map((card, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Card>
                                    <CardContent sx={{
                                        textAlign: 'center',
                                        '& svg': {
                                            filter: 'drop-shadow(0 0 8px rgba(0, 255, 0, 0.4))',
                                        }
                                    }}>
                                        <IconButton
                                            size="large"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            {card.icon}
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            sx={{
                                                color: 'primary.main',
                                                textShadow: '0 0 8px rgba(0, 255, 0, 0.4)'
                                            }}
                                        >
                                            &gt; {card.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                opacity: 0.7,
                                                color: 'primary.main',
                                                textShadow: '0 0 8px rgba(0, 255, 0, 0.4)'
                                            }}
                                        >
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <ContactUsForm />
            </Box>
        </ThemeProvider>
    );
}

export default MarketingPage;