import {createTheme} from '@mui/material/styles';

export const terminalTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#00ff00',
            light: '#33ff33',
            dark: '#00cc00',
        },
        background: {
            default: '#000000',
            paper: '#000000',
        },
        text: {
            primary: '#00ff00',
            secondary: '#00cc00',
        },
    },
    typography: {
        fontFamily: 'Courier New, monospace',
        h1: {
            letterSpacing: '0.1em',
        },
        h2: {
            letterSpacing: '0.1em',
        },
        h5: {
            letterSpacing: '0.1em',
        },
        h6: {
            letterSpacing: '0.1em',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    border: '1px solid #00ff00',
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.3s ease',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '32px 16px',
                    '&:last-child': {
                        paddingBottom: '32px',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#00ff00',
                    textShadow: '0 0 8px rgba(0, 255, 0, 0.4)',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textShadow: '0 0 8px rgba(0, 255, 0, 0.4)',
                },
            },
        },
    },
});
